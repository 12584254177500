<template>
  <div>
    <header class="px-4 py-0 text-white w-full flex justify-start shadow-inner"></header>

    <div v-if="loaded" class="bg-white divide-y divide-gray-200 shadow-inner">
      <div v-for="(m, key) in modules" :key="key" class="py-1 flex-col justify-between">
        <span @click="loadAndexpand(m.name)" class="px-2">{{ m.name }}</span>
        <transition name="fade">
          <div v-show="expanded.includes(m.name)" class="px-2 shadow bg-gray-600 text-white">{{ m }}</div>
        </transition>
      </div>
    </div>
    <div v-if="loading">
      <svg
        class="w-6 h-6 animate-spin"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
        ></path>
      </svg>
    </div>
    {{ registryDiagnoses }}
    <div v-if="!modules">
      <h3 class="font-bold">{{ $t("list.noresult") }}</h3>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ModulesAdmin",
  data() {
    return {
      loading: false,
      loaded: false,
      selectedModule: null,
      expanded: [],
    };
  },
  beforeMount() {
    this.load();
  },
  computed: {
    ...mapState("admin", {
      modules: state => state.modules,
    }),
    ...mapState("meta", {
      registryDiagnoses: state => state.registryDiagnoses,
    }),
  },
  methods: {
    load() {
      this.loading = true;
      this.$store.dispatch("admin/getModules").then(() => {
        this.loading = false;
        this.loaded = true;
      });
      this.$store.dispatch("meta/registryDiagnoses").then(() => {
        console.log("ok");
      });
    },
    loadAndexpand(name) {
      if (!this.expanded.includes(name)) {
        this.$store.dispatch("admin/getRelationsForModule", name).then(() => {
          this.expanded.push(name);
        });
      } else {
        this.expanded.splice(this.expanded.indexOf(name), 1);
      }
    },
  },
};
</script>

<style>
</style>