<template>
  <div class="w-full">
    <iframe class="bg-white w-full h-screen pt-16" src="http://localhost:9999" frameborder="0"></iframe>
    <!-- <module-editor /> -->
  </div>
</template>

<script>
// import ModuleEditor from "@/components/admin/ModuleEditor";

export default {
  name: "AppAdmin",
  components: {
    // ModuleEditor,
  },
};
</script>
