<template>
  <div>
    <header class="px-4 py-2 bg-indigo-900 text-white w-full flex justify-start">
      <nav class="shadow-sm">
        <cm-button @click="edit(null)">{{ $t("admin.users.new") }}</cm-button>
      </nav>
    </header>

    <div class="bg-white">
      <div class="flex justify-between">
        <div class="px-4 py-2">
          <form action="#">
            <div class="flex">
              <div class="mr-2">
                <label for="pid" class="block text-sm font-medium leading-5 text-gray-700">{{
                  $t("admin.user.pid")
                }}</label>
                <div class="rounded-xs">
                  <input
                    v-focus
                    v-model="searchColumns.pid"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="mr-2">
                <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">{{
                  $t("admin.user.last_name")
                }}</label>
                <div class="rounded-xs">
                  <input
                    v-model="searchColumns.last_name"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="mr-2">
                <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">{{
                  $t("admin.user.first_name")
                }}</label>
                <div class="rounded-xs">
                  <input
                    v-model="searchColumns.first_name"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="mr-2">
                <label for="id" class="block text-sm font-medium leading-5 text-gray-700">{{
                  $t("admin.user.id")
                }}</label>
                <div class="rounded-xs">
                  <input
                    v-model="searchColumns.id"
                    type="text"
                    autocomplete="off"
                    required
                    class="appearance-none block w-full px-1 py-1 border border-gray-300 rounded-xs placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-600 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  />
                </div>
              </div>
              <div class="flex items-end">
                <cm-button @click.prevent="search" type="submit">
                  {{ $t("button.search") }}
                </cm-button>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div
            v-if="userlist && userlist.total > 0"
            class="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-3"
          >
            <div class="flex-1 flex justify-between sm:hidden">
              <a
                href="#"
                class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-600 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                >{{ $t("button.previous") }}</a
              >
              <a
                href="#"
                class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:ring-1 focus:ring-blue-600 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                >{{ $t("button.next") }}</a
              >
            </div>
            <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
              <div class="mr-4">
                <p class="text-sm leading-5 text-gray-700">
                  {{ $t("pager.label.showing") }}
                  <span class="font-medium">{{ offset + 1 }}</span>
                  {{ $t("pager.label.to") }}
                  <span class="font-medium">{{ pagerToNumber }}</span>
                  {{ $t("pager.label.of") }}
                  <span class="font-medium">{{ userlist.total }}</span>
                  {{ $t("pager.label.results") }}
                </p>
              </div>
              <div v-show="userlist.total > limit">
                <span class="relative z-0 inline-flex shadow-sm">
                  <button
                    type="button"
                    :disabled="offset === 0"
                    class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-1 focus:outline-none focus:ring-1 focus:ring-blue-600 ring-inset active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                    @click="prevPage"
                    :class="{ 'opacity-25': offset === 0 }"
                  >
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>

                  <button
                    type="button"
                    v-for="page in pages"
                    :key="page"
                    :class="{ 'font-semibold text-gray-900': page == currentPage }"
                    @click="gotopage(page)"
                    class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:z-1 focus:outline-none focus:ring-1 focus:ring-blue-600 ring-inset active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150"
                  >
                    {{ page }}
                  </button>

                  <button
                    type="button"
                    :disabled="currentPage === pages"
                    @click="nextPage"
                    class="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm leading-5 font-medium text-gray-500 hover:text-gray-400 focus:z-1 focus:outline-none focus:ring-1 focus:ring-blue-600 ring-inset active:bg-gray-100 active:text-gray-500 transition ease-in-out duration-150"
                    :class="{ 'opacity-25': currentPage === pages }"
                  >
                    <svg class="h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"
                      />
                    </svg>
                  </button>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4" v-if="userlist && userlist.data && userlist.data.length">
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-4 py-3 border-b border-gray-200 bg-white text-left text-sm leading-4 font-medium text-gray-800 tracking-wider truncate"
                @click="updateSort('id')"
              >
                {{ $t("admin.user.id") }}
              </th>

              <th
                class="px-4 py-3 border-b border-gray-200 bg-white text-left text-sm leading-4 font-medium text-gray-800 tracking-wider truncate"
                @click="updateSort('last_name')"
              >
                {{ $t("admin.user.last_name") }}
              </th>

              <th
                class="px-4 py-3 border-b border-gray-200 bg-white text-left text-sm leading-4 font-medium text-gray-800 tracking-wider truncate"
                @click="updateSort('first_name')"
              >
                {{ $t("admin.user.first_name") }}
              </th>

              <th
                class="px-4 py-3 border-b border-gray-200 bg-white text-left text-sm leading-4 font-medium text-gray-800 tracking-wider truncate"
                @click="updateSort('pid')"
              >
                {{ $t("admin.user.pid") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(user, key) in userlist.data"
              :key="key"
              class="group odd:bg-gray-100 first:border-t border-b border-gray-200"
              @click="edit(user)"
            >
              <td class="px-4 py-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                <div class="text-sm leading-5 text-gray-900">{{ user.id }}</div>
              </td>

              <td class="px-4 py-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                <div class="text-sm leading-5 text-gray-900">{{ user.last_name }}</div>
              </td>

              <td class="px-4 py-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                <div class="text-sm leading-5 text-gray-900">{{ user.first_name }}</div>
              </td>

              <td class="px-4 py-2 align-top group-hover:bg-blue-100 group-hover:cursor-pointer">
                <div class="text-sm leading-5 text-gray-900">{{ user.pid }}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="p-4">
        <h3 class="font-bold">{{ $t("list.noresult") }}</h3>
      </div>
    </div>
    <modal
      name="user-form"
      :click-to-close="false"
      :scrollable="true"
      :resizable="true"
      :draggable="false"
      :adaptive="false"
      :reset="true"
      :pivotY="0.1"
      :height="'auto'"
      :width="'50%'"
      classes="bg-white rounded shadow-md transform transition-all object-contain overflow-visible"
    >
      <user-edit :user="selectedUser" @cancel="cancel" />
    </modal>
  </div>
</template>

<script>
import { mapState } from "vuex";
import UserEdit from "@/components/admin/UserEdit";

export default {
  name: "UserAdmin",
  components: {
    UserEdit,
  },
  data() {
    return {
      loaded: false,
      offset: 0,
      limit: 25,
      sort: {
        col: "id",
        asc: true,
      },
      searchColumns: {
        pid: null,
        id: null,
        first_name: null,
        last_name: null,
      },
      maxButtons: 5,
      selectedUser: null,
    };
  },
  computed: {
    ...mapState("admin", {
      userlist: state => state.userlist,
    }),
    query() {
      let sortObj = {
        sort_column: this.sort.col,
        sort_order: this.sort.asc ? "asc" : "desc",
      };

      let search = {
        pid: this.searchColumns.pid,
        id: this.searchColumns.id,
        first_name: this.searchColumns.first_name,
        last_name: this.searchColumns.last_name,
      };

      Object.keys(search).forEach(key => {
        !search[key] && delete search[key];
      });

      let paginationObj = {
        offset: this.offset,
        limit: this.limit,
      };

      let searchString = new URLSearchParams(search).toString();
      let sortString = new URLSearchParams(sortObj).toString();
      let paginationString = new URLSearchParams(paginationObj).toString();

      return [searchString, sortString, paginationString].join("&");
    },
    currentPage() {
      return Math.floor(this.offset / this.limit) + 1;
    },
    pages() {
      if (!this.userlist?.total) {
        return null;
      }

      return Math.ceil(this.userlist.total / this.limit);
    },
    pagerToNumber() {
      if (!this.userlist?.total) {
        return null;
      }

      return this.currentPage === this.pages ? this.userlist.total : this.offset + this.limit;
    },
  },
  methods: {
    search() {
      this.loaded = false;
      this.$store
        .dispatch("admin/getUserlist", {
          query: this.query,
        })
        .then(() => {
          this.loaded = true;
        });
    },
    prevPage() {
      this.offset = this.offset >= this.limit ? this.offset - this.limit : 0;
      this.search();
    },
    nextPage() {
      this.offset = this.offset <= this.patientlist.total ? this.offset + this.limit : this.total - this.limit;

      this.search();
    },
    gotopage(page) {
      this.offset = page > 1 ? (page - 1) * this.limit : 0;

      this.search();
    },
    updateSort(header) {
      this.sort.col = header;
      this.sort.asc = !this.sort.asc;
      this.search();
    },
    edit(user) {
      this.$modal.show("user-form");
      this.selectedUser = user;
    },
    cancel() {
      this.selectedUser = null;
      this.$modal.hide("user-form");
    },
  },
};
</script>

<style>
</style>